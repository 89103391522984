import { Content, Header, Item, Root, Trigger } from '@radix-ui/react-accordion'
import { ChevronDown } from 'lucide-react'
import { type ComponentPropsWithoutRef, forwardRef } from 'react'

import { cn } from '@/utils'

export const Accordion = Root

const AccordionItemComponent = forwardRef<
	HTMLDivElement,
	ComponentPropsWithoutRef<typeof Item>
>(({ className, ...props }, ref) => (
	<Item
		ref={ref}
		className={cn('border-b border-gray-800', className)}
		{...props}
	/>
))
AccordionItemComponent.displayName = 'AccordionItem'
export const AccordionItem = AccordionItemComponent

const AccordionTriggerComponent = forwardRef<
	HTMLButtonElement,
	ComponentPropsWithoutRef<typeof Trigger>
>(({ className, children, ...props }, ref) => (
	<Header className="flex">
		<Trigger
			ref={ref}
			className={cn(
				'flex flex-1 items-center gap-3 py-4 text-left text-lg font-bold transition-all hover:text-red-500 [&[data-state=open]>svg]:rotate-180',
				className,
			)}
			{...props}
		>
			<ChevronDown
				width={20}
				height={20}
				strokeWidth={2}
				className="shrink-0 opacity-60 transition-transform duration-200"
				aria-hidden="true"
			/>
			{children}
		</Trigger>
	</Header>
))
AccordionTriggerComponent.displayName = 'AccordionTrigger'
export const AccordionTrigger = AccordionTriggerComponent

const AccordionContentComponent = forwardRef<
	HTMLDivElement,
	ComponentPropsWithoutRef<typeof Content>
>(({ className, children, ...props }, ref) => (
	<Content
		ref={ref}
		className={cn(
			'overflow-hidden text-base data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
			className,
		)}
		{...props}
	>
		<div className={cn('pb-4 pt-0 pl-9')}>{children}</div>
	</Content>
))
AccordionContentComponent.displayName = 'AccordionContent'
export const AccordionContent = AccordionContentComponent
