import { motion } from 'framer-motion'
import type { FC, PropsWithChildren } from 'react'

import { staggerContainer } from '@/utils'

const Main: FC<PropsWithChildren> = ({ children }) => (
	<main>
		<section className="pt-28 pb-16 md:py-24 relative bg-black">
			<div className="absolute inset-0 bg-gradient-radial from-red-900/10 via-transparent to-transparent opacity-30" />
			<motion.div
				className="container mx-auto px-4 md:px-6 max-w-7xl relative"
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true, margin: '-100px' }}
				variants={staggerContainer}
			>
				{children}
			</motion.div>
		</section>
	</main>
)

export default Main
