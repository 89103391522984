import { AnimatePresence, motion } from 'framer-motion'
import { Briefcase, Car, Clock, PhoneCall, Wrench } from 'lucide-react'
import type React from 'react'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { useTypedTranslation } from '@/hooks'
import { cn, posthog } from '@/utils'

import logoImage from '/static/images/logo.png'

import LanguageSwitcher from './LanguageSwitcher'
import MovingBorderButton from './MovingBorderButton'

interface NavItem {
	name: string
	key: string
	url: string
	icon: React.ComponentType<{
		size?: number
		strokeWidth?: number
		className?: string
	}>
}

interface NavBarProps {
	className?: string
	onBookNowClick: () => void
}

const NavBar: React.FC<NavBarProps> = ({ className, onBookNowClick }) => {
	const { t } = useTypedTranslation()
	const navigate = useNavigate()
	const location = useLocation()
	const [activeTab, setActiveTab] = useState('home')
	const [scrolled, setScrolled] = useState(false)
	const [menuOpen, setMenuOpen] = useState(false)
	const [menuAnimating, setMenuAnimating] = useState(false)
	const [animationKey, setAnimationKey] = useState(0)
	const bodyLockedRef = useRef(false)
	const scrollAnimationFrame = useRef<number | null>(null)
	const resizeAnimationFrame = useRef<number | null>(null)

	const navItems: NavItem[] = useMemo(
		() => [
			{ name: t('navigation.home'), key: 'home', url: '/', icon: Car },
			{
				name: t('navigation.prices'),
				key: 'prices',
				url: '/prices',
				icon: Wrench,
			},
			{
				name: t('navigation.careers'),
				key: 'careers',
				url: '/careers',
				icon: Briefcase,
			},
			{
				name: t('navigation.aboutUs'),
				key: 'about-us',
				url: '/about-us',
				icon: PhoneCall,
			},
		],
		[t],
	)

	// Sync active tab with current location
	useEffect(() => {
		const matchingItem = navItems.find((item) => item.url === location.pathname)
		if (matchingItem) {
			setActiveTab(matchingItem.key)
		}
	}, [location.pathname, navItems])

	const unlockBodyScroll = useCallback(() => {
		document.body.style.overflow = ''
		document.body.style.position = ''
		document.body.style.top = ''
		document.body.style.width = ''
		document.body.style.touchAction = ''
		bodyLockedRef.current = false
	}, [])

	const lockBodyScroll = useCallback(() => {
		const scrollY = window.scrollY
		document.body.style.position = 'fixed'
		document.body.style.top = `-${scrollY}px`
		document.body.style.width = '100%'
		document.body.style.overflow = 'hidden'
		document.body.style.touchAction = 'none'
		bodyLockedRef.current = true
	}, [])

	const toggleMenu = useCallback(
		(open: boolean) => {
			setMenuOpen(open)
			open ? lockBodyScroll() : unlockBodyScroll()
		},
		[lockBodyScroll, unlockBodyScroll],
	)

	const handleNavClick = useCallback(
		(key: string, url: string) => {
			setActiveTab(key)
			setMenuOpen(false)
			if (bodyLockedRef.current) unlockBodyScroll()
			navigate(url)
			// Track both custom navigation and pageview events
			posthog?.capture(`Navigation Clicked: ${key}`)
			// Smooth scroll to top using requestAnimationFrame
			requestAnimationFrame(() => {
				window.scrollTo({ top: 0, behavior: 'smooth' })
				setScrolled(false)
			})
			setAnimationKey((prev) => prev + 1)
		},
		[navigate, unlockBodyScroll],
	)

	const handleBookNowClick = useCallback(() => {
		posthog?.capture('Book Now Clicked')
		setActiveTab('home')
		onBookNowClick()
	}, [onBookNowClick])

	// Throttle scroll and resize handlers using requestAnimationFrame
	useEffect(() => {
		const handleScroll = () => {
			if (scrollAnimationFrame.current !== null) return
			scrollAnimationFrame.current = requestAnimationFrame(() => {
				setScrolled(window.scrollY > 50)
				scrollAnimationFrame.current = null
			})
		}

		const handleResize = () => {
			if (resizeAnimationFrame.current !== null) return
			resizeAnimationFrame.current = requestAnimationFrame(() => {
				// If viewport is resized to desktop while menu is open, close the menu
				if (window.innerWidth >= 768 && menuOpen) toggleMenu(false)
				resizeAnimationFrame.current = null
			})
		}

		// Initialize on mount
		handleScroll()
		handleResize()

		window.addEventListener('scroll', handleScroll, { passive: true })
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('scroll', handleScroll)
			window.removeEventListener('resize', handleResize)
			if (scrollAnimationFrame.current)
				cancelAnimationFrame(scrollAnimationFrame.current)
			if (resizeAnimationFrame.current)
				cancelAnimationFrame(resizeAnimationFrame.current)
			if (bodyLockedRef.current) unlockBodyScroll()
		}
	}, [menuOpen, toggleMenu, unlockBodyScroll])

	// Trigger initial animation key update on mount
	useEffect(() => {
		setAnimationKey((prev) => prev + 1)
	}, [])

	// Listen for custom updateActiveTab events from other components
	useEffect(() => {
		const handleUpdateActiveTab = (event: CustomEvent<{ tab: string }>) => {
			setActiveTab(event.detail.tab)
		}

		window.addEventListener(
			'updateActiveTab',
			handleUpdateActiveTab as EventListener,
		)

		return () => {
			window.removeEventListener(
				'updateActiveTab',
				handleUpdateActiveTab as EventListener,
			)
		}
	}, [])

	return (
		<div
			className={cn(
				'fixed top-0 left-0 right-0 z-[1000] transition-all duration-300 will-change-transform',
				menuOpen || menuAnimating || scrolled
					? 'bg-black/30 backdrop-blur-xl shadow-lg shadow-black/10'
					: 'bg-transparent',
				className,
			)}
			style={{
				backdropFilter:
					menuOpen || menuAnimating || scrolled ? 'blur(10px)' : 'none',
				WebkitBackdropFilter:
					menuOpen || menuAnimating || scrolled ? 'blur(10px)' : 'none',
			}}
		>
			<div className="w-full flex items-center justify-between px-4 md:px-6 py-3 max-w-7xl mx-auto">
				{/* Logo */}
				<div
					className={`flex items-center relative z-[1001] ${menuOpen ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300`}
				>
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault()
							handleNavClick('home', '/')
						}}
						className="text-xl md:text-2xl font-display font-bold text-white flex items-center"
					>
						<img src={logoImage} alt="SMotors Logo" className="w-20 h-8 mr-2" />
						<span>
							<span className="text-red-500">S</span>
							<span className="text-white">Motors</span>
						</span>
					</a>
				</div>

				{/* Mobile Menu Button */}
				<button
					type="button"
					className="md:hidden flex justify-center items-center w-10 h-10 relative z-[102]"
					onClick={() => toggleMenu(!menuOpen)}
					aria-label="Toggle menu"
				>
					<div className="w-6 h-6 flex flex-col justify-center items-center">
						<span
							className={cn(
								'block h-0.5 w-6 bg-white transform transition-all duration-300 ease-out',
								menuOpen ? 'rotate-45 translate-y-1.5' : '',
							)}
						/>
						<span
							className={cn(
								'block h-0.5 w-6 bg-white my-1 transition-all duration-300 ease-out',
								menuOpen ? 'opacity-0 translate-x-2' : 'opacity-100',
							)}
						/>
						<span
							className={cn(
								'block h-0.5 w-6 bg-white transform transition-all duration-300 ease-out',
								menuOpen ? '-rotate-45 -translate-y-1.5' : '',
							)}
						/>
					</div>
				</button>

				{/* Mobile Navigation Menu */}
				<AnimatePresence>
					{menuOpen && (
						<motion.div
							className="md:hidden fixed inset-0 bg-black z-[101]"
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.2 }}
							onAnimationStart={() => setMenuAnimating(true)}
							onAnimationComplete={() => {
								if (!menuOpen) {
									setTimeout(() => {
										setMenuAnimating(false)
									}, 50)
								}
							}}
						>
							<div className="flex flex-col h-[100vh] bg-black">
								{/* Menu Header with Logo */}
								<div className="flex items-center justify-between px-4 py-4 border-b border-white/10 bg-black/40 backdrop-blur-sm relative z-10">
									<div className="flex-1">
										<a
											href="/"
											onClick={(e) => {
												e.preventDefault()
												handleNavClick('home', '/')
											}}
											className="text-xl font-display font-bold text-white"
										>
											<span>
												<span className="text-red-500">S</span>
												<span className="text-white">Motors</span>
											</span>
										</a>
									</div>

									{/* Book Now and Close Button Area */}
									<div className="flex items-center justify-end flex-1">
										<button
											type="button"
											onClick={(e) => {
												e.stopPropagation()
												toggleMenu(false)
												requestAnimationFrame(() => {
													setTimeout(() => {
														handleBookNowClick()
													}, 300)
												})
											}}
											className="px-5 py-1.5 bg-red-600 hover:bg-red-700 text-white text-head font-medium rounded-md transition-colors duration-300 mr-3 min-w-[120px] whitespace-nowrap text-center"
										>
											{t('navigation.bookNow')}
										</button>
										<div
											className="w-10 h-10 relative flex items-center justify-center"
											aria-hidden="true"
										>
											<div className="w-6 h-6" />
										</div>
									</div>
								</div>

								{/* Menu Items */}
								<div className="flex-1 overflow-y-auto py-8 bg-gradient-to-b from-black to-black/95">
									<div className="flex flex-col items-center space-y-4">
										{navItems.map((item, idx) => {
											const isActive = activeTab === item.key
											return (
												<Link
													key={`${item.key}-${idx}`}
													to={item.url}
													className={cn(
														'relative cursor-pointer text-lg font-medium flex items-center px-6 py-4 w-full max-w-sm mx-auto rounded-lg transition-all duration-200',
														isActive
															? 'text-white bg-gradient-to-r from-red-950/30 to-black/40 border-l-2 border-red-500 pl-5 shadow-sm'
															: 'text-white/70 hover:text-white hover:bg-white/5',
													)}
													onClick={(e) => {
														e.preventDefault()
														handleNavClick(item.key, item.url)
														toggleMenu(false)
													}}
												>
													<item.icon
														size={24}
														strokeWidth={2}
														className={cn(
															'mr-4 flex-shrink-0',
															isActive ? 'text-red-500' : 'text-white/70',
														)}
													/>
													<span>{item.name}</span>
												</Link>
											)
										})}

										<div className="w-full max-w-[350px] mx-auto px-6 mt-6">
											<div className="border-t border-white/10 pt-6 flex justify-center">
												<LanguageSwitcher className="flex justify-center" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</motion.div>
					)}
				</AnimatePresence>

				{/* Desktop Navigation */}
				<div className="hidden md:flex items-center justify-center absolute left-0 right-0 mx-auto">
					<div className="flex items-center gap-8">
						{navItems.map((item, idx) => {
							const isActive = activeTab === item.key
							return (
								<Link
									key={`${item.key}-${idx}`}
									to={item.url}
									className={cn(
										'relative cursor-pointer text-sm font-medium transition-all duration-300',
										isActive ? 'text-white' : 'text-white/60 hover:text-white',
									)}
									onClick={(e) => {
										e.preventDefault()
										handleNavClick(item.key, item.url)
									}}
								>
									<span>{item.name}</span>
									{isActive && (
										<motion.div
											key={animationKey}
											className="absolute left-0 right-0 h-0.5 bg-red-500 bottom-[-6px]"
											initial={{ width: '0%', left: '50%' }}
											animate={{ width: '100%', left: '0%' }}
											transition={{ duration: 0.3, ease: 'easeInOut' }}
										/>
									)}
								</Link>
							)
						})}
					</div>
				</div>

				{/* Right side items: Language Switcher, Book Now Button, and Working Hours */}
				<div className="hidden md:flex items-center">
					<LanguageSwitcher className="mr-4" />
					<MovingBorderButton
						onClick={handleBookNowClick}
						containerClassName="h-10 w-28 mr-6"
						borderRadius="0.5rem"
						className="text-sm font-medium"
					>
						{t('navigation.bookNow')}
					</MovingBorderButton>
					<div className="flex items-center text-sm text-white/70">
						<Clock size={14} className="mr-2 text-red-500" />
						<span>{t('navigation.workingHours')}</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default memo(NavBar)
